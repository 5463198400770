@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://rsms.me/inter/inter.css');
@import url('https://fonts.googleapis.com/css2?family=Supply+Mono&display=swap');

/* Global styles */
html,
body,
#root {
  @apply m-0 p-0 w-full h-full;
}